import React from 'react';

const AnalogClock = (props) => {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4">
                <path d="M1.02209 1.57651L130.423 130.977" stroke="#E1E1E1" strokeWidth="0.8" strokeDasharray="1.6 1.6" />
                <path d="M1.87048 130.272L131.271 0.871307" stroke="#E1E1E1" strokeWidth="0.8" strokeDasharray="1.6 1.6" />
            </g>
            <path d="M60.533 118.332L60.621 116.201" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M73.421 118.332L73.333 116.201" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M54.133 117.203L54.663 115.137" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M80.263 117.203L79.733 115.137" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M46.667 114.969L47.485 112.999" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M86.951 114.969L86.133 112.999" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M119.198 71.152L117.066 71.064" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M119.198 58.602L117.066 58.689" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M118.066 78.332L116 77.802" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M117 51.133L114.933 51.662" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M115.837 85.02L113.867 84.201" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M114.77 44.734L112.8 45.553" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M14.6665 71.488L16.798 71.4" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M24.2665 97.148L26.021 95.935" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M98.957 107.367L97.867 105.534" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M36 23.4023L37.189 25.1735" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M14.6666 58.602L16.7981 58.689" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M15.7334 78.332L17.7999 77.802" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M28.5331 101.754L29.9965 100.202" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M104.648 102.836L103.2 101.27" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M30.6666 27.668L32.1984 29.1527" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M16.7998 51.133L18.8663 51.662" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M17.8665 85.02L19.8367 84.201" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M33.8667 107.281L35.0944 105.537" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M109.123 97.281L107.467 95.937" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M26.3999 33L28.1274 34.2518" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M18.9332 44.734L20.9034 45.553" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M60.533 13.8008L60.621 15.9322" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M73.421 13.8008L73.333 15.9322" stroke="#939395" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M54.133 14.8672L54.663 16.9337" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M80.263 14.8672L79.733 16.9337" stroke="#939395" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M47.733 17L48.552 18.9702" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M86.951 17L86.133 18.9702" stroke="#939395" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M99.246 24.4688L97.866 26.0962" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M108.012 33L106.4 34.3972" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M103.612 28.7344L102.133 30.272" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M94.667 113.496L92.533 109.801" stroke="#6F6F6F" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M114.362 92.734L110.666 90.601" stroke="#6F6F6F" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M87.706 27.8617C87.706 27.3953 87.865 27.0145 88.184 26.7191C88.503 26.4215 88.912 26.2727 89.411 26.2727C89.894 26.2727 90.293 26.4098 90.61 26.684C90.926 26.9582 91.084 27.3039 91.084 27.7211C91.084 27.9906 91.005 28.2578 90.845 28.5227C90.688 28.7852 90.398 29.1309 89.974 29.5598L88.845 30.7023V30.7621H91.165V31.4688H87.748V30.8992L89.464 29.1133C89.801 28.7617 90.026 28.4969 90.139 28.3188C90.251 28.1383 90.308 27.9543 90.308 27.7668C90.308 27.5348 90.22 27.3414 90.044 27.1867C89.868 27.032 89.649 26.9547 89.386 26.9547C89.108 26.9547 88.881 27.0379 88.708 27.2043C88.537 27.3707 88.451 27.5898 88.451 27.8617V27.8723H87.706V27.8617Z" fill="#939395" />
            <path d="M105.881 88.782C105.527 89.056 105.068 89.193 104.503 89.193C103.938 89.193 103.479 89.056 103.125 88.782C102.773 88.508 102.597 88.152 102.597 87.713C102.597 87.39 102.695 87.11 102.889 86.873C103.086 86.636 103.347 86.485 103.673 86.42V86.36C103.408 86.292 103.196 86.157 103.037 85.955C102.88 85.752 102.801 85.514 102.801 85.242C102.801 84.841 102.961 84.513 103.279 84.257C103.598 84.002 104.006 83.874 104.503 83.874C105 83.874 105.408 84.002 105.726 84.257C106.045 84.513 106.204 84.841 106.204 85.242C106.204 85.516 106.126 85.754 105.969 85.955C105.812 86.157 105.6 86.292 105.333 86.36V86.42C105.658 86.48 105.92 86.629 106.117 86.866C106.313 87.1 106.412 87.382 106.412 87.71C106.412 88.148 106.235 88.505 105.881 88.782ZM103.708 88.293C103.912 88.457 104.177 88.539 104.503 88.539C104.829 88.539 105.093 88.457 105.297 88.293C105.501 88.127 105.603 87.911 105.603 87.646C105.603 87.382 105.501 87.166 105.297 87C105.093 86.833 104.829 86.75 104.503 86.75C104.177 86.75 103.912 86.833 103.708 87C103.504 87.166 103.402 87.382 103.402 87.646C103.402 87.911 103.504 88.127 103.708 88.293ZM103.831 85.889C104.002 86.034 104.226 86.107 104.503 86.107C104.779 86.107 105.003 86.034 105.174 85.889C105.345 85.743 105.431 85.554 105.431 85.319C105.431 85.082 105.345 84.89 105.174 84.743C105.003 84.595 104.779 84.521 104.503 84.521C104.226 84.521 104.002 84.595 103.831 84.743C103.663 84.89 103.578 85.082 103.578 85.319C103.578 85.554 103.663 85.743 103.831 85.889Z" fill="#939395" />
            <path d="M86.831 105.07V100.845H86.771L85.499 101.745V100.939L86.835 99.997H87.619V105.07H86.831ZM90.888 105.193C90.295 105.193 89.834 104.96 89.503 104.494C89.175 104.027 89.011 103.372 89.011 102.529C89.011 101.692 89.176 101.04 89.507 100.574C89.837 100.107 90.298 99.874 90.888 99.874C91.474 99.874 91.932 100.109 92.263 100.577C92.593 101.044 92.759 101.696 92.759 102.536C92.759 103.377 92.595 104.031 92.266 104.497C91.938 104.961 91.479 105.193 90.888 105.193ZM90.087 103.995C90.274 104.334 90.541 104.504 90.888 104.504C91.235 104.504 91.501 104.336 91.686 103.998C91.871 103.658 91.964 103.168 91.964 102.529C91.964 101.896 91.87 101.411 91.683 101.073C91.495 100.733 91.23 100.563 90.888 100.563C90.546 100.563 90.28 100.733 90.09 101.073C89.9 101.413 89.805 101.898 89.805 102.529C89.805 103.166 89.899 103.655 90.087 103.995Z" fill="#939395" />
            <path d="M66.933 11.668V15.9346" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M12.5332 65H16.7999" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M66.933 120.469V116.202" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M40.267 18.0703L42.4 21.7654" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M20 37.266L23.695 39.399" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M94.667 19.1328L92.533 22.8279" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M113.295 37.266L109.6 39.399" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M39.2 113.496L41.333 109.801" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M18.9331 91.668L22.6281 89.535" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M121.333 65H117.066" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M66.66 26.2241C65.919 26.2241 65.342 25.9326 64.929 25.3496C64.519 24.7666 64.313 23.9478 64.313 22.8931C64.313 21.8472 64.52 21.0327 64.933 20.4497C65.346 19.8667 65.922 19.5752 66.66 19.5752C67.393 19.5752 67.965 19.8682 68.378 20.4541C68.792 21.0371 68.998 21.853 68.998 22.9019C68.998 23.9536 68.793 24.771 68.383 25.354C67.973 25.9341 67.398 26.2241 66.66 26.2241ZM65.658 24.7256C65.893 25.1504 66.227 25.3628 66.66 25.3628C67.094 25.3628 67.426 25.1519 67.658 24.73C67.889 24.3052 68.005 23.6929 68.005 22.8931C68.005 22.1021 67.888 21.4956 67.653 21.0737C67.419 20.6489 67.088 20.4365 66.66 20.4365C66.232 20.4365 65.9 20.6489 65.663 21.0737C65.425 21.4985 65.307 22.105 65.307 22.8931C65.307 23.6899 65.424 24.3008 65.658 24.7256Z" fill="white" />
            <path d="M21.1392 68.734V63.452H21.0645L19.4736 64.577V63.571L21.1436 62.393H22.1235V68.734H21.1392ZM27.9683 68.374C27.5259 68.717 26.9517 68.888 26.2456 68.888C25.5395 68.888 24.9653 68.717 24.5229 68.374C24.0835 68.031 23.8638 67.586 23.8638 67.038C23.8638 66.634 23.9854 66.284 24.2285 65.988C24.4746 65.692 24.8013 65.503 25.2085 65.421V65.346C24.8774 65.261 24.6123 65.093 24.4131 64.841C24.2168 64.586 24.1187 64.289 24.1187 63.949C24.1187 63.448 24.3179 63.038 24.7163 62.718C25.1147 62.399 25.6245 62.239 26.2456 62.239C26.8667 62.239 27.3765 62.399 27.7749 62.718C28.1733 63.038 28.3726 63.448 28.3726 63.949C28.3726 64.292 28.2744 64.589 28.0781 64.841C27.8818 65.093 27.6167 65.261 27.2827 65.346V65.421C27.6899 65.497 28.0166 65.683 28.2627 65.979C28.5088 66.272 28.6318 66.624 28.6318 67.034C28.6318 67.582 28.4106 68.028 27.9683 68.374ZM25.2524 67.763C25.5073 67.968 25.8384 68.071 26.2456 68.071C26.6528 68.071 26.9839 67.968 27.2388 67.763C27.4937 67.555 27.6211 67.286 27.6211 66.955C27.6211 66.624 27.4937 66.354 27.2388 66.146C26.9839 65.938 26.6528 65.834 26.2456 65.834C25.8384 65.834 25.5073 65.938 25.2524 66.146C24.9976 66.354 24.8701 66.624 24.8701 66.955C24.8701 67.286 24.9976 67.555 25.2524 67.763ZM25.4062 64.757C25.6201 64.939 25.8999 65.03 26.2456 65.03C26.5913 65.03 26.8711 64.939 27.085 64.757C27.2988 64.576 27.4058 64.338 27.4058 64.045C27.4058 63.75 27.2988 63.509 27.085 63.325C26.8711 63.14 26.5913 63.048 26.2456 63.048C25.8999 63.048 25.6201 63.14 25.4062 63.325C25.1953 63.509 25.0898 63.75 25.0898 64.045C25.0898 64.338 25.1953 64.576 25.4062 64.757Z" fill="white" />
            <path d="M62.739 111.402V106.12H62.665L61.074 107.245V106.239L62.744 105.061H63.724V111.402H62.739ZM65.517 106.894C65.517 106.311 65.716 105.834 66.114 105.465C66.513 105.093 67.024 104.907 67.648 104.907C68.251 104.907 68.751 105.079 69.146 105.421C69.542 105.764 69.74 106.196 69.74 106.718C69.74 107.055 69.64 107.389 69.441 107.72C69.245 108.048 68.881 108.48 68.351 109.016L66.94 110.444V110.519H69.841V111.402H65.569V110.69L67.714 108.458C68.136 108.019 68.417 107.688 68.558 107.465C68.698 107.239 68.769 107.009 68.769 106.775C68.769 106.485 68.659 106.243 68.439 106.05C68.219 105.856 67.945 105.76 67.617 105.76C67.269 105.76 66.986 105.864 66.769 106.072C66.555 106.28 66.448 106.554 66.448 106.894V106.907H65.517V106.894Z" fill="white" />
            <path d="M110.495 68.888C110.026 68.888 109.613 68.775 109.255 68.55C108.901 68.324 108.62 67.999 108.412 67.574C108.154 67.067 108.025 66.424 108.025 65.645C108.025 64.57 108.245 63.733 108.684 63.136C109.126 62.538 109.745 62.239 110.539 62.239C111.098 62.239 111.574 62.395 111.967 62.705C112.359 63.013 112.604 63.426 112.701 63.944H111.712C111.633 63.687 111.488 63.483 111.277 63.333C111.066 63.184 110.817 63.109 110.53 63.109C110.043 63.109 109.664 63.32 109.392 63.742C109.122 64.161 108.981 64.753 108.97 65.518H109.044C109.197 65.213 109.418 64.977 109.708 64.81C110.001 64.643 110.335 64.56 110.71 64.56C111.313 64.56 111.812 64.759 112.204 65.157C112.597 65.556 112.793 66.061 112.793 66.673C112.793 67.321 112.578 67.853 112.147 68.269C111.716 68.682 111.166 68.888 110.495 68.888ZM109.537 67.64C109.792 67.895 110.108 68.022 110.486 68.022C110.864 68.022 111.18 67.896 111.435 67.645C111.69 67.393 111.817 67.079 111.817 66.704C111.817 66.32 111.693 66.005 111.444 65.759C111.198 65.513 110.88 65.39 110.49 65.39C110.104 65.39 109.784 65.513 109.532 65.759C109.28 66.002 109.154 66.312 109.154 66.687C109.154 67.064 109.282 67.382 109.537 67.64Z" fill="white" />
            <path d="M105.016 44.266V43.285H102.541V42.554C102.935 41.834 103.639 40.714 104.654 39.193H105.775V42.582H106.475V43.285H105.775V44.266H105.016ZM103.297 42.55V42.599H105.027V39.885H104.981C104.386 40.748 103.824 41.636 103.297 42.55Z" fill="#939395" />
            <path d="M40.965 105.07V100.845H40.905L39.632 101.745V100.939L40.968 99.997H41.752V105.07H40.965ZM45.563 105.07V104.089H43.088V103.358C43.482 102.639 44.186 101.518 45.201 99.997H46.322V103.386H47.022V104.089H46.322V105.07H45.563ZM43.844 103.355V103.404H45.574V100.69H45.528C44.933 101.552 44.371 102.441 43.844 103.355Z" fill="#939395" />
            <path d="M27.0979 89.07V84.845H27.0381L25.7654 85.745V84.939L27.1014 83.997H27.8854V89.07H27.0979ZM31.2357 89.193C30.8607 89.193 30.5303 89.103 30.2443 88.923C29.9607 88.742 29.7357 88.482 29.5693 88.142C29.3631 87.737 29.26 87.222 29.26 86.599C29.26 85.739 29.4357 85.07 29.7873 84.591C30.1412 84.113 30.6357 83.874 31.2709 83.874C31.7186 83.874 32.0994 83.998 32.4135 84.247C32.7275 84.493 32.9232 84.823 33.0006 85.238H32.2096C32.1463 85.032 32.0303 84.869 31.8615 84.75C31.6928 84.63 31.4936 84.57 31.2639 84.57C30.8748 84.57 30.5713 84.739 30.3533 85.077C30.1377 85.412 30.0252 85.885 30.0158 86.497H30.0756C30.1975 86.253 30.3744 86.064 30.6064 85.931C30.8408 85.797 31.108 85.73 31.408 85.73C31.8908 85.73 32.2893 85.89 32.6033 86.209C32.9174 86.527 33.0744 86.932 33.0744 87.421C33.0744 87.939 32.9021 88.365 32.5576 88.698C32.2131 89.028 31.7725 89.193 31.2357 89.193ZM30.4693 88.195C30.6732 88.399 30.9264 88.501 31.2287 88.501C31.5311 88.501 31.7842 88.4 31.9881 88.198C32.192 87.997 32.2939 87.746 32.2939 87.446C32.2939 87.139 32.1943 86.887 31.9951 86.69C31.7982 86.493 31.5439 86.395 31.2322 86.395C30.9229 86.395 30.6674 86.493 30.4658 86.69C30.2643 86.885 30.1635 87.132 30.1635 87.432C30.1635 87.734 30.2654 87.989 30.4693 88.195Z" fill="#939395" />
            <path d="M25.8393 40.659C25.8393 40.192 25.9986 39.811 26.3174 39.516C26.6361 39.218 27.0451 39.07 27.5443 39.07C28.0271 39.07 28.4268 39.207 28.7432 39.481C29.0596 39.755 29.2178 40.101 29.2178 40.518C29.2178 40.788 29.1381 41.055 28.9787 41.32C28.8217 41.582 28.5311 41.928 28.1068 42.357L26.9783 43.499V43.559H29.2986V44.266H25.8814V43.696L27.5971 41.91C27.9346 41.559 28.1596 41.294 28.2721 41.116C28.3846 40.935 28.4408 40.751 28.4408 40.564C28.4408 40.332 28.3529 40.138 28.1771 39.984C28.0014 39.829 27.7822 39.752 27.5197 39.752C27.2408 39.752 27.0146 39.835 26.8412 40.001C26.6701 40.168 26.5846 40.387 26.5846 40.659V40.669H25.8393V40.659ZM32.1252 44.389C31.5322 44.389 31.0705 44.155 30.74 43.689C30.4119 43.223 30.2479 42.568 30.2479 41.724C30.2479 40.887 30.4131 40.236 30.7436 39.769C31.074 39.303 31.5346 39.07 32.1252 39.07C32.7111 39.07 33.1693 39.304 33.4998 39.773C33.8303 40.239 33.9955 40.892 33.9955 41.731C33.9955 42.572 33.8314 43.226 33.5033 43.693C33.1752 44.157 32.7158 44.389 32.1252 44.389ZM31.3236 43.19C31.5111 43.53 31.7783 43.7 32.1252 43.7C32.4721 43.7 32.7381 43.531 32.9232 43.193C33.1084 42.854 33.201 42.364 33.201 41.724C33.201 41.091 33.1072 40.606 32.9197 40.268C32.7322 39.929 32.4674 39.759 32.1252 39.759C31.783 39.759 31.517 39.929 31.3271 40.268C31.1373 40.608 31.0424 41.093 31.0424 41.724C31.0424 42.361 31.1361 42.85 31.3236 43.19Z" fill="#939395" />
            <path d="M40.773 27.8617C40.773 27.3953 40.932 27.0145 41.251 26.7191C41.57 26.4215 41.979 26.2727 42.478 26.2727C42.961 26.2727 43.36 26.4098 43.677 26.684C43.993 26.9582 44.151 27.3039 44.151 27.7211C44.151 27.9906 44.072 28.2578 43.912 28.5227C43.755 28.7852 43.465 29.1309 43.04 29.5598L41.912 30.7023V30.7621H44.232V31.4688H40.815V30.8992L42.531 29.1133C42.868 28.7617 43.093 28.4969 43.206 28.3188C43.318 28.1383 43.374 27.9543 43.374 27.7668C43.374 27.5348 43.287 27.3414 43.111 27.1867C42.935 27.032 42.716 26.9547 42.453 26.9547C42.174 26.9547 41.948 27.0379 41.775 27.2043C41.604 27.3707 41.518 27.5898 41.518 27.8617V27.8723H40.773V27.8617ZM45.224 27.8617C45.224 27.3953 45.383 27.0145 45.702 26.7191C46.021 26.4215 46.429 26.2727 46.929 26.2727C47.412 26.2727 47.811 26.4098 48.128 26.684C48.444 26.9582 48.602 27.3039 48.602 27.7211C48.602 27.9906 48.522 28.2578 48.363 28.5227C48.206 28.7852 47.915 29.1309 47.491 29.5598L46.363 30.7023V30.7621H48.683V31.4688H45.266V30.8992L46.981 29.1133C47.319 28.7617 47.544 28.4969 47.656 28.3188C47.769 28.1383 47.825 27.9543 47.825 27.7668C47.825 27.5348 47.737 27.3414 47.562 27.1867C47.386 27.032 47.167 26.9547 46.904 26.9547C46.625 26.9547 46.399 27.0379 46.226 27.2043C46.054 27.3707 45.969 27.5898 45.969 27.8617V27.8723H45.224V27.8617Z" fill="#939395" />
            <path d="M60.533 118.332L60.621 116.201" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M73.421 118.332L73.333 116.201" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M54.133 117.203L54.663 115.137" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M80.263 117.203L79.733 115.137" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M46.667 114.969L47.485 112.999" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M86.951 114.969L86.133 112.999" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M119.198 71.152L117.066 71.064" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M119.198 58.602L117.066 58.689" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M118.066 78.332L116 77.802" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M117 51.133L114.933 51.662" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M115.837 85.02L113.867 84.201" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M114.77 44.734L112.8 45.553" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M14.6665 71.488L16.798 71.4" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M24.2665 97.148L26.021 95.935" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M98.957 107.367L97.867 105.534" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M36 23.4023L37.189 25.1735" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M14.6666 58.602L16.7981 58.689" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M15.7334 78.332L17.7999 77.802" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M28.5331 101.754L29.9965 100.202" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M104.648 102.836L103.2 101.27" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M30.6666 27.668L32.1984 29.1527" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M16.7998 51.133L18.8663 51.662" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M17.8665 85.02L19.8367 84.201" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M33.8667 107.281L35.0944 105.537" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M109.123 97.281L107.467 95.937" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M26.3999 33L28.1274 34.2518" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M18.9332 44.734L20.9034 45.553" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M60.533 13.8008L60.621 15.9322" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M73.421 13.8008L73.333 15.9322" stroke="#939395" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M54.133 14.8672L54.663 16.9337" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M80.263 14.8672L79.733 16.9337" stroke="#939395" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M47.733 17L48.552 18.9702" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M86.951 17L86.133 18.9702" stroke="#939395" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M99.246 24.4688L97.866 26.0962" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M108.012 33L106.4 34.3972" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M103.612 28.7344L102.133 30.272" stroke="#ADADAD" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M94.667 113.496L92.533 109.801" stroke="#6F6F6F" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M114.362 92.734L110.666 90.601" stroke="#6F6F6F" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M87.706 27.8617C87.706 27.3953 87.865 27.0145 88.184 26.7191C88.503 26.4215 88.912 26.2727 89.411 26.2727C89.894 26.2727 90.293 26.4098 90.61 26.684C90.926 26.9582 91.084 27.3039 91.084 27.7211C91.084 27.9906 91.005 28.2578 90.845 28.5227C90.688 28.7852 90.398 29.1309 89.974 29.5598L88.845 30.7023V30.7621H91.165V31.4688H87.748V30.8992L89.464 29.1133C89.801 28.7617 90.026 28.4969 90.139 28.3188C90.251 28.1383 90.308 27.9543 90.308 27.7668C90.308 27.5348 90.22 27.3414 90.044 27.1867C89.868 27.032 89.649 26.9547 89.386 26.9547C89.108 26.9547 88.881 27.0379 88.708 27.2043C88.537 27.3707 88.451 27.5898 88.451 27.8617V27.8723H87.706V27.8617Z" fill="#939395" />
            <path d="M105.881 88.782C105.527 89.056 105.068 89.193 104.503 89.193C103.938 89.193 103.479 89.056 103.125 88.782C102.773 88.508 102.597 88.152 102.597 87.713C102.597 87.39 102.695 87.11 102.889 86.873C103.086 86.636 103.347 86.485 103.673 86.42V86.36C103.408 86.292 103.196 86.157 103.037 85.955C102.88 85.752 102.801 85.514 102.801 85.242C102.801 84.841 102.961 84.513 103.279 84.257C103.598 84.002 104.006 83.874 104.503 83.874C105 83.874 105.408 84.002 105.726 84.257C106.045 84.513 106.204 84.841 106.204 85.242C106.204 85.516 106.126 85.754 105.969 85.955C105.812 86.157 105.6 86.292 105.333 86.36V86.42C105.658 86.48 105.92 86.629 106.117 86.866C106.313 87.1 106.412 87.382 106.412 87.71C106.412 88.148 106.235 88.505 105.881 88.782ZM103.708 88.293C103.912 88.457 104.177 88.539 104.503 88.539C104.829 88.539 105.093 88.457 105.297 88.293C105.501 88.127 105.603 87.911 105.603 87.646C105.603 87.382 105.501 87.166 105.297 87C105.093 86.833 104.829 86.75 104.503 86.75C104.177 86.75 103.912 86.833 103.708 87C103.504 87.166 103.402 87.382 103.402 87.646C103.402 87.911 103.504 88.127 103.708 88.293ZM103.831 85.889C104.002 86.034 104.226 86.107 104.503 86.107C104.779 86.107 105.003 86.034 105.174 85.889C105.345 85.743 105.431 85.554 105.431 85.319C105.431 85.082 105.345 84.89 105.174 84.743C105.003 84.595 104.779 84.521 104.503 84.521C104.226 84.521 104.002 84.595 103.831 84.743C103.663 84.89 103.578 85.082 103.578 85.319C103.578 85.554 103.663 85.743 103.831 85.889Z" fill="#939395" />
            <path d="M86.831 105.07V100.845H86.771L85.499 101.745V100.939L86.835 99.997H87.619V105.07H86.831ZM90.888 105.193C90.295 105.193 89.834 104.96 89.503 104.494C89.175 104.027 89.011 103.372 89.011 102.529C89.011 101.692 89.176 101.04 89.507 100.574C89.837 100.107 90.298 99.874 90.888 99.874C91.474 99.874 91.932 100.109 92.263 100.577C92.593 101.044 92.759 101.696 92.759 102.536C92.759 103.377 92.595 104.031 92.266 104.497C91.938 104.961 91.479 105.193 90.888 105.193ZM90.087 103.995C90.274 104.334 90.541 104.504 90.888 104.504C91.235 104.504 91.501 104.336 91.686 103.998C91.871 103.658 91.964 103.168 91.964 102.529C91.964 101.896 91.87 101.411 91.683 101.073C91.495 100.733 91.23 100.563 90.888 100.563C90.546 100.563 90.28 100.733 90.09 101.073C89.9 101.413 89.805 101.898 89.805 102.529C89.805 103.166 89.899 103.655 90.087 103.995Z" fill="#939395" />
            <path d="M66.933 11.668V15.9346" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M12.5332 65H16.7999" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M66.933 120.469V116.202" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M40.267 18.0703L42.4 21.7654" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M20 37.266L23.695 39.399" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M94.667 19.1328L92.533 22.8279" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M113.295 37.266L109.6 39.399" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M39.2 113.496L41.333 109.801" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M18.9331 91.668L22.6281 89.535" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M121.333 65H117.066" stroke="white" strokeWidth="0.9" strokeLinecap="round" />
            <path d="M62.739 111.402V106.12H62.665L61.074 107.245V106.239L62.744 105.061H63.724V111.402H62.739ZM65.517 106.894C65.517 106.311 65.716 105.834 66.114 105.465C66.513 105.093 67.024 104.907 67.648 104.907C68.251 104.907 68.751 105.079 69.146 105.421C69.542 105.764 69.74 106.196 69.74 106.718C69.74 107.055 69.64 107.389 69.441 107.72C69.245 108.048 68.881 108.48 68.351 109.016L66.94 110.444V110.519H69.841V111.402H65.569V110.69L67.714 108.458C68.136 108.019 68.417 107.688 68.558 107.465C68.698 107.239 68.769 107.009 68.769 106.775C68.769 106.485 68.659 106.243 68.439 106.05C68.219 105.856 67.945 105.76 67.617 105.76C67.269 105.76 66.986 105.864 66.769 106.072C66.555 106.28 66.448 106.554 66.448 106.894V106.907H65.517V106.894Z" fill="white" />
            <path d="M105.016 44.266V43.285H102.541V42.554C102.935 41.834 103.639 40.714 104.654 39.193H105.775V42.582H106.475V43.285H105.775V44.266H105.016ZM103.297 42.55V42.599H105.027V39.885H104.981C104.386 40.748 103.824 41.636 103.297 42.55Z" fill="#939395" />
            <path d="M40.965 105.07V100.845H40.905L39.632 101.745V100.939L40.968 99.997H41.752V105.07H40.965ZM45.563 105.07V104.089H43.088V103.358C43.482 102.639 44.186 101.518 45.201 99.997H46.322V103.386H47.022V104.089H46.322V105.07H45.563ZM43.844 103.355V103.404H45.574V100.69H45.528C44.933 101.552 44.371 102.441 43.844 103.355Z" fill="#939395" />
            <path d="M110.495 68.888C110.026 68.888 109.613 68.775 109.255 68.55C108.901 68.324 108.62 67.999 108.412 67.574C108.154 67.067 108.025 66.424 108.025 65.645C108.025 64.57 108.245 63.733 108.684 63.136C109.126 62.538 109.745 62.239 110.539 62.239C111.098 62.239 111.574 62.395 111.967 62.705C112.359 63.013 112.604 63.426 112.701 63.944H111.712C111.633 63.687 111.488 63.483 111.277 63.333C111.066 63.184 110.817 63.109 110.53 63.109C110.043 63.109 109.664 63.32 109.392 63.742C109.122 64.161 108.981 64.753 108.97 65.518H109.044C109.197 65.213 109.418 64.977 109.708 64.81C110.001 64.643 110.335 64.56 110.71 64.56C111.313 64.56 111.812 64.759 112.204 65.157C112.597 65.556 112.793 66.061 112.793 66.673C112.793 67.321 112.578 67.853 112.147 68.269C111.716 68.682 111.166 68.888 110.495 68.888ZM109.537 67.64C109.792 67.895 110.108 68.022 110.486 68.022C110.864 68.022 111.18 67.896 111.435 67.645C111.69 67.393 111.817 67.079 111.817 66.704C111.817 66.32 111.693 66.005 111.444 65.759C111.198 65.513 110.88 65.39 110.49 65.39C110.104 65.39 109.784 65.513 109.532 65.759C109.28 66.002 109.154 66.312 109.154 66.687C109.154 67.064 109.282 67.382 109.537 67.64Z" fill="white" />
            <path d="M66.66 26.2241C65.919 26.2241 65.342 25.9326 64.929 25.3496C64.519 24.7666 64.313 23.9478 64.313 22.8931C64.313 21.8472 64.52 21.0327 64.933 20.4497C65.346 19.8667 65.922 19.5752 66.66 19.5752C67.393 19.5752 67.965 19.8682 68.378 20.4541C68.792 21.0371 68.998 21.853 68.998 22.9019C68.998 23.9536 68.793 24.771 68.383 25.354C67.973 25.9341 67.398 26.2241 66.66 26.2241ZM65.658 24.7256C65.893 25.1504 66.227 25.3628 66.66 25.3628C67.094 25.3628 67.426 25.1519 67.658 24.73C67.889 24.3052 68.005 23.6929 68.005 22.8931C68.005 22.1021 67.888 21.4956 67.653 21.0737C67.419 20.6489 67.088 20.4365 66.66 20.4365C66.232 20.4365 65.9 20.6489 65.663 21.0737C65.425 21.4985 65.307 22.105 65.307 22.8931C65.307 23.6899 65.424 24.3008 65.658 24.7256Z" fill="white" />
            <path d="M21.1392 68.734V63.452H21.0645L19.4736 64.577V63.571L21.1436 62.393H22.1235V68.734H21.1392ZM27.9683 68.374C27.5259 68.717 26.9517 68.888 26.2456 68.888C25.5395 68.888 24.9653 68.717 24.5229 68.374C24.0835 68.031 23.8638 67.586 23.8638 67.038C23.8638 66.634 23.9854 66.284 24.2285 65.988C24.4746 65.692 24.8013 65.503 25.2085 65.421V65.346C24.8774 65.261 24.6123 65.093 24.4131 64.841C24.2168 64.586 24.1187 64.289 24.1187 63.949C24.1187 63.448 24.3179 63.038 24.7163 62.718C25.1147 62.399 25.6245 62.239 26.2456 62.239C26.8667 62.239 27.3765 62.399 27.7749 62.718C28.1733 63.038 28.3726 63.448 28.3726 63.949C28.3726 64.292 28.2744 64.589 28.0781 64.841C27.8818 65.093 27.6167 65.261 27.2827 65.346V65.421C27.6899 65.497 28.0166 65.683 28.2627 65.979C28.5088 66.272 28.6318 66.624 28.6318 67.034C28.6318 67.582 28.4106 68.028 27.9683 68.374ZM25.2524 67.763C25.5073 67.968 25.8384 68.071 26.2456 68.071C26.6528 68.071 26.9839 67.968 27.2388 67.763C27.4937 67.555 27.6211 67.286 27.6211 66.955C27.6211 66.624 27.4937 66.354 27.2388 66.146C26.9839 65.938 26.6528 65.834 26.2456 65.834C25.8384 65.834 25.5073 65.938 25.2524 66.146C24.9976 66.354 24.8701 66.624 24.8701 66.955C24.8701 67.286 24.9976 67.555 25.2524 67.763ZM25.4062 64.757C25.6201 64.939 25.8999 65.03 26.2456 65.03C26.5913 65.03 26.8711 64.939 27.085 64.757C27.2988 64.576 27.4058 64.338 27.4058 64.045C27.4058 63.75 27.2988 63.509 27.085 63.325C26.8711 63.14 26.5913 63.048 26.2456 63.048C25.8999 63.048 25.6201 63.14 25.4062 63.325C25.1953 63.509 25.0898 63.75 25.0898 64.045C25.0898 64.338 25.1953 64.576 25.4062 64.757Z" fill="white" />
            <path d="M27.0979 89.07V84.845H27.0381L25.7654 85.745V84.939L27.1014 83.997H27.8854V89.07H27.0979ZM31.2357 89.193C30.8607 89.193 30.5303 89.103 30.2443 88.923C29.9607 88.742 29.7357 88.482 29.5693 88.142C29.3631 87.737 29.26 87.222 29.26 86.599C29.26 85.739 29.4357 85.07 29.7873 84.591C30.1412 84.113 30.6357 83.874 31.2709 83.874C31.7186 83.874 32.0994 83.998 32.4135 84.247C32.7275 84.493 32.9232 84.823 33.0006 85.238H32.2096C32.1463 85.032 32.0303 84.869 31.8615 84.75C31.6928 84.63 31.4936 84.57 31.2639 84.57C30.8748 84.57 30.5713 84.739 30.3533 85.077C30.1377 85.412 30.0252 85.885 30.0158 86.497H30.0756C30.1975 86.253 30.3744 86.064 30.6064 85.931C30.8408 85.797 31.108 85.73 31.408 85.73C31.8908 85.73 32.2893 85.89 32.6033 86.209C32.9174 86.527 33.0744 86.932 33.0744 87.421C33.0744 87.939 32.9021 88.365 32.5576 88.698C32.2131 89.028 31.7725 89.193 31.2357 89.193ZM30.4693 88.195C30.6732 88.399 30.9264 88.501 31.2287 88.501C31.5311 88.501 31.7842 88.4 31.9881 88.198C32.192 87.997 32.2939 87.746 32.2939 87.446C32.2939 87.139 32.1943 86.887 31.9951 86.69C31.7982 86.493 31.5439 86.395 31.2322 86.395C30.9229 86.395 30.6674 86.493 30.4658 86.69C30.2643 86.885 30.1635 87.132 30.1635 87.432C30.1635 87.734 30.2654 87.989 30.4693 88.195Z" fill="#939395" />
            <path d="M25.8393 40.659C25.8393 40.192 25.9986 39.811 26.3174 39.516C26.6361 39.218 27.0451 39.07 27.5443 39.07C28.0271 39.07 28.4268 39.207 28.7432 39.481C29.0596 39.755 29.2178 40.101 29.2178 40.518C29.2178 40.788 29.1381 41.055 28.9787 41.32C28.8217 41.582 28.5311 41.928 28.1068 42.357L26.9783 43.499V43.559H29.2986V44.266H25.8814V43.696L27.5971 41.91C27.9346 41.559 28.1596 41.294 28.2721 41.116C28.3846 40.935 28.4408 40.751 28.4408 40.564C28.4408 40.332 28.3529 40.138 28.1771 39.984C28.0014 39.829 27.7822 39.752 27.5197 39.752C27.2408 39.752 27.0146 39.835 26.8412 40.001C26.6701 40.168 26.5846 40.387 26.5846 40.659V40.669H25.8393V40.659ZM32.1252 44.389C31.5322 44.389 31.0705 44.155 30.74 43.689C30.4119 43.223 30.2479 42.568 30.2479 41.724C30.2479 40.887 30.4131 40.236 30.7436 39.769C31.074 39.303 31.5346 39.07 32.1252 39.07C32.7111 39.07 33.1693 39.304 33.4998 39.773C33.8303 40.239 33.9955 40.892 33.9955 41.731C33.9955 42.572 33.8314 43.226 33.5033 43.693C33.1752 44.157 32.7158 44.389 32.1252 44.389ZM31.3236 43.19C31.5111 43.53 31.7783 43.7 32.1252 43.7C32.4721 43.7 32.7381 43.531 32.9232 43.193C33.1084 42.854 33.201 42.364 33.201 41.724C33.201 41.091 33.1072 40.606 32.9197 40.268C32.7322 39.929 32.4674 39.759 32.1252 39.759C31.783 39.759 31.517 39.929 31.3271 40.268C31.1373 40.608 31.0424 41.093 31.0424 41.724C31.0424 42.361 31.1361 42.85 31.3236 43.19Z" fill="#939395" />
            <path d="M40.773 27.8617C40.773 27.3953 40.932 27.0145 41.251 26.7191C41.57 26.4215 41.979 26.2727 42.478 26.2727C42.961 26.2727 43.36 26.4098 43.677 26.684C43.993 26.9582 44.151 27.3039 44.151 27.7211C44.151 27.9906 44.072 28.2578 43.912 28.5227C43.755 28.7852 43.465 29.1309 43.04 29.5598L41.912 30.7023V30.7621H44.232V31.4688H40.815V30.8992L42.531 29.1133C42.868 28.7617 43.093 28.4969 43.206 28.3188C43.318 28.1383 43.374 27.9543 43.374 27.7668C43.374 27.5348 43.287 27.3414 43.111 27.1867C42.935 27.032 42.716 26.9547 42.453 26.9547C42.174 26.9547 41.948 27.0379 41.775 27.2043C41.604 27.3707 41.518 27.5898 41.518 27.8617V27.8723H40.773V27.8617ZM45.224 27.8617C45.224 27.3953 45.383 27.0145 45.702 26.7191C46.021 26.4215 46.429 26.2727 46.929 26.2727C47.412 26.2727 47.811 26.4098 48.128 26.684C48.444 26.9582 48.602 27.3039 48.602 27.7211C48.602 27.9906 48.522 28.2578 48.363 28.5227C48.206 28.7852 47.915 29.1309 47.491 29.5598L46.363 30.7023V30.7621H48.683V31.4688H45.266V30.8992L46.981 29.1133C47.319 28.7617 47.544 28.4969 47.656 28.3188C47.769 28.1383 47.825 27.9543 47.825 27.7668C47.825 27.5348 47.737 27.3414 47.562 27.1867C47.386 27.032 47.167 26.9547 46.904 26.9547C46.625 26.9547 46.399 27.0379 46.226 27.2043C46.054 27.3707 45.969 27.5898 45.969 27.8617V27.8723H45.224V27.8617Z" fill="#939395" />
        </svg>
    );
};

const ImageAssets = {
    AnalogClock,
}

export default ImageAssets;